<template>
  <div style="padding-bottom: 4rem">
    <v-form
      :lazy-validation="true"
      @submit.prevent="handleSubmit"
      v-model="valid"
      ref="form"
    >
      <v-card>
        <v-card-text class="elevation-2">
          <span class="title">{{ $t("user.userInfo") }}</span>
        </v-card-text>
        <v-divider></v-divider>
        <UserRegistrationInputs v-on:update-user="updateUserInformation" />

        <v-card-text class="elevation-2">
          <span class="title">{{ $t("company.companyInfo") }}</span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            :label="$t('company.fields.name')"
            name="name"
            prepend-icon="mdi-account-box-outline"
            type="text"
            v-model="companyInput.name"
            :rules="stringRules"
          ></v-text-field>
        </v-card-text>

        <v-card-text>
          <v-text-field
            :label="$t('company.fields.orgNumber')"
            name="orgNumber"
            prepend-icon="mdi-domain"
            type="text"
            v-model="companyInput.orgNumber"
          ></v-text-field>
        </v-card-text>

        <v-card-text>
          <v-text-field
            :label="$t('company.fields.postalAdress')"
            name="postalAdress"
            prepend-icon="mdi-home-city-outline"
            type="text"
            v-model="companyInput.postalAdress"
            :rules="stringRules"
          ></v-text-field>
        </v-card-text>

        <v-card-text>
          <v-text-field
            :label="$t('company.fields.deliveryAdress')"
            name="deliveryAdress"
            prepend-icon="mdi-home-city-outline"
            type="text"
            v-model="companyInput.deliveryAdress"
          ></v-text-field>
        </v-card-text>

        <v-card-text>
          <phone-number-input v-model="companyInput.contactPhonenumber" />
        </v-card-text>

        <v-card-text>
          <v-text-field
            :label="$t('company.fields.invoiceAdress')"
            name="invoiceAdress"
            prepend-icon="mdi-at"
            type="text"
            v-model="companyInput.invoiceAdress"
            :rules="stringRules"
          ></v-text-field>
        </v-card-text>

        <v-card-text>
          <v-text-field
            :label="$t('company.fields.refrenceNmuber')"
            name="refrenceNmuber"
            prepend-icon="mdi-account-box-outline"
            type="text"
            v-model="companyInput.refrenceNmuber"
          ></v-text-field>
        </v-card-text>

        <v-card-text>
          <LangSwitch v-on:update-lang="updateCountry" />
        </v-card-text>
        <v-card-text
          style="
            width: 90%;
            margin: 0 auto;
            border-radius: 10px;
            background-color: rgb(238, 85, 85);
            color: white;
          "
          v-if="errorMessage != null"
        >
          <h3 style="width: 90%; margin: 0 auto">{{ errorMessage }}</h3>
        </v-card-text>
        <v-card-text>
          <v-btn color="primary" type="submit" block x-large>{{
            $t("common.register")
          }}</v-btn>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import UserRegistrationInputs from "@/components/user/UserRegistrationInputs";
import { mapActions } from "vuex";
import LangSwitch from "@/components/company/LangSwitch";

export default {
  name: "UserCompanyRegister",
  data() {
    return {
      valid: false,
      image: null,

      stringRules: [
        (v) => !!v || this.$t("validation.requiredSingle"),
        (v) => (v && v.length <= 255) || this.$t("validation.toLong"),
      ],

      phoneNumberRules: [
        (v) => !!v || this.$t("validation.requiredSingle"),
        (v) => v[0] !== "0" || this.$t("validation.contryCodeError"),
      ],

      userInput: {},
      companyInput: {
        name: "",
        orgNumber: "",
        postalAdress: "",
        deliveryAdress: "",
        contactPhonenumber: "",
        invoiceAdress: "",
        refrenceNmuber: "",
        country: "",
      },

      errorMessage: null,
    };
  },

  methods: {
    ...mapActions("users", ["createUserAndCompany"]),
    ...mapActions("companies", ["postImage"]),
    ...mapActions("configuration", ["getCanCreateBools"]),

    async handleSubmit() {
      if (!this.$refs.form.validate()) {
        this.valid = false;
        return;
      }

      var payload = { ...this.userInput, ...this.companyInput };

      try {
        await this.createUserAndCompany(payload);
      } catch (e) {
        this.errorMessage = e.message;
        return;
      }

      this.$router.push("/");
    },

    updateUserInformation(user) {
      this.userInput = user;
    },

    updateCountry(c) {
      this.companyInput.country = c;
    },
  },

  async created() {
    await this.getCanCreateBools();
  },

  components: {
    UserRegistrationInputs,
    LangSwitch,
  },
};
</script>
<style>
.licenseError {
  width: 80%;
  margin: 0 auto;
  border-radius: 10px;
  background-color: rgb(238, 85, 85);
  color: white;
  height: 40px;
}
</style>
